import React from "react";

const Settings = (props) => {


    return(<>
    <h1 className="welcome" id="welcome">Welcome back, {props.name}</h1>
       
       
    <div className="settings" id="settings">

<div className="audiosection">
    
        <div className="setting">
        <span class="material-symbols-outlined">music_note</span>
        <label for="va" id="musiclabel">Music | 50%</label>
        </div>
        <input type="range" id="volume_audios" className="volume_audios" name="va" min={0} max={100}></input>
        </div>

        <div className="audiosection">
        <div className="setting">
        <span class="material-symbols-outlined">flare</span>
        <label for="ve" id="effectlabel">Effects | 50%</label>
        </div>
        <input type="range" id="volume_effects" className="volume_effects" name="ve" min={0} max={100}></input>

        </div>
        
        </div>
    </>)
}

export default Settings;