import React from "react";

import notch from "../images/default.png";
import bg from "../images/bg3.png";

const Client = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let hasName = urlParams.has("name");
  let hasUuid = urlParams.has("uuid");
  let hasPin = urlParams.has("pin");
  let valid = false;

  if (hasName && hasPin && hasUuid) {
    valid = true;
  }

  return (
    <>
      <div className="main">
        <img src={bg} alt="" className="bg"></img>;
        <header>
          <div className="rightlink">
            <a
              onClick={() => {
                window.open("https://europarksmc.eu/user/sources");
              }}
            >
              Sources
            </a>
          </div>
          <div className="leftlink">
            <a
              onClick={() => {
                window.open("https://umidy.net/terms/privacy/");
              }}
            >
              Impressum
            </a>
          </div>
        </header>
        <div className="panel">
          <div className="top">
            <div className="left">
              <img src={notch} alt="" id="skin"></img>
            </div>
            <div className="right" id="right">
              <h1 className="welcome" id="welcome">
                {valid
                  ? "Click here to start!"
                  : "Please use /audio ingame to get a working Audioclient"}
              </h1>
            </div>
          </div>
        </div>
        <div className="footer">
          <p>
            This website is not produced by, endorsed by, supported by, or
            affiliated with Europa-Park GmbH & Co Mack KG The ownership rights
            to certain images and audio files are vested in third parties.
          </p>
        </div>
      </div>
    </>
  );
};

export default Client;
