import "./App.css";
import Client from "./components/client";

function App() {
  return (
    <>
      <Client />
    </>
  );
}

export default App;
